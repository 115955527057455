import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {db,functions} from './../firebase';
import {MinsToStr,StrToMins,CompareTimeStamp} from "./../Methods.js";
import { doc, updateDoc, increment } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export const EditUserPage = ({state,ShowModal,setState,handleChange,ResetFields,Back,translation}) => {

  useEffect(() => {
    ViewEditUserPage(user);
  },[])

  const {eventID,activityGroupID,activityID,userType,userID} = useParams();
  const eventItem = state.events[eventID];
  const activityGroupItem = state.activityGroups[activityGroupID];
  const activityItem = state.activities[activityID];
  const user = state.users[userID];
  const [deleteCheck, setDeleteCheck] = useState(false);
  const [reRegisterCheck, setReRegisterCheck] = useState(false);
  const [extraEmailCheck, setExtraEmailCheck] = useState(false);
  const navigate = useNavigate();
  const emailAdmin = httpsCallable(functions, "emailEW");
  // v2
  // const emailAdmin = httpsCallable(functions, "emailEWSecondGen");
  let emailCheckBoolActivity = false;
  let emailCheckBoolGroup = false;
  const userRef = doc(db, 'users', userID);
  const activityRef = doc(db, 'activities', activityID);

  const emailCheck = (reRegisterBool) => {
    const reRegister = reRegisterBool ? activityItem.ticketsLeft > 0 ? "confirmed" : "waiting" : null;
    if (!activityGroupItem.multiReg) {
      Object.values(state.users).forEach(childItem => {
        if (childItem.role === "user" && childItem.userType !== "cancelled" && childItem.email.toLowerCase() === state.email.toLowerCase() && childItem.activityGroupID === activityGroupID) emailCheckBoolGroup = true;
      })
    }
    Object.values(state.users).forEach(childItem => {
      if (childItem.role === "user" && childItem.userType !== "cancelled" && childItem.email.toLowerCase() === state.email.toLowerCase() && childItem.activityID === activityID) emailCheckBoolActivity = true;
    })
    let formCheck = false;
    if (!state.email || !state.firstName || !state.lastName || !state.gdpr || (!state.phone && state.phoneRequired) || (!state.address && state.addressRequired) || (!state.title && state.titleRequired) || (!state.comment && state.commentRequired) || (!state.institution && state.institutionRequired) || (!state.country && state.countryRequired)) formCheck = true;
    if (formCheck) ShowModal("alert", "Please complete required fields");
    else if ((emailCheckBoolActivity || emailCheckBoolGroup) && state.email !== state.oldEmail) ShowModal("alert", "User already registered for this activity or group (email address is already used). Please use another email address.");
    else EditUser(eventID,activityGroupID,activityID,reRegisterBool ? reRegister : userType,userID, reRegisterBool);
  }

  const ViewEditUserPage = (user) => {
    if (!user) return;
    setState((prevState) => ({
      ...prevState,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      oldEmail: user.email,
      gdpr: user.gdpr || false,
      optIn: user.optIn || false,
      emailConfirmation: user.emailConfirmation || false,
      timeStamp: user.timeStamp,
      title: user.title,
      phone: user.phone,
      institution: user.institution,
      address: user.address,
      country: user.country,
      comment: user.comment,
      userType: user.userType
    }));
  }

  const DeleteUser = async (eventID,activityGroupID,activityID,userType,userID) => {
    // References
    const timeStamp = Date.now();
    // Cancel user
    updateDoc(userRef, {
      "userType": "cancelled",
      "emailConfirmation": state.emailConfirmation || false,
      "optIn": state.optIn || false,
      "lastUpdated": timeStamp,
      "selfReg": null
    });
    // Move waiting list person
    const waitingUser = Object.values(state.users).filter((item) => item.activityID === activityID && item.userType === "waiting").sort(CompareTimeStamp)[0];
    if (userType === "confirmed" && activityItem.open && waitingUser) {
      // Waiting user references
      const waitingUserIDRef = doc(db, "users", waitingUser.userID);
      // const userItem = await getDoc(waitingUserIDRef);
      // Move waiting user to registered
      updateDoc(waitingUserIDRef,{
        "userType": "confirmed",
        "lastUpdated": timeStamp
      })
      // if (waitingUser.emailConfirmation) ConfirmationEmail(userItem.data(), state.events[eventID].eventName, state.events[eventID].eventLogo, activityItem );
    }
    else if (userType === "confirmed") {
      updateDoc(activityRef,{
        "ticketsLeft": increment(+1)
      })
    }    
    // Finish
    navigate("/activity-overview/" + eventID + "/" + activityGroupID + "/" + activityID);
    ResetFields();
  }

  const SendExtraEmailInvite = () => {
    ExtraConfirmationEmail(state.events[eventID].language, user, state.events[eventID].eventName, state.events[eventID].eventLogo, activityItem, state.userType);
    setExtraEmailCheck(false);
  }

  const ExtraConfirmationEmail = (language, toUser, eventName, eventLogo, activity, emailType ) => {
    const activityEndTime = MinsToStr(StrToMins(activity.duration) + StrToMins(activity.startTime));
    const calendarStartTime = activity.startDate + " " + activity.startTime + ":00Z";
    const calendarEndTime = activity.startDate + " " + activityEndTime + ":00Z";
    const reverseStartDate = activity.startDate.split("-").reverse().join("-");
    const subject = (emailType === "confirmed" ? translation[language].confirmedFor : translation[language].waitingFor) + " " + activity.activityName + " " + eventName;
    const message = translation[language].thankYouRegister + " " + activity.activityName + " " + activity.activityLocation + " " + activity.startDate + ".\n\n" + translation[language].weLookForward;
    emailAdmin({
      toUser: toUser,
      eventName: eventName,
      eventLogo: eventLogo,
      language: language,
      subject: subject,
      message: message,
      activity: activity,
      calendarStartTime: calendarStartTime,
      activityEndTime: activityEndTime,
      calendarEndTime: calendarEndTime,
      timeZone: eventItem.timeZone,
      reverseStartDate: reverseStartDate,
      emailType: emailType,
    })
    .then((result) => {
      // console.log(result);
    })
    .catch((error) => {
      // console.log(error);
    })
  }

  const EditUser = async (eventID,activityGroupID,activityID,userType,userID,reRegisterBool) => {
    const timeStamp = Date.now();
    updateDoc(userRef,{
      "firstName": state.firstName,
      "lastName": state.lastName,
      "email": state.email,
      "gdpr": state.gdpr || false,
      "optIn": state.optIn || false,
      "emailConfirmation": state.emailConfirmation || false,
      "title": state.title || null,
      "phone": state.phone || null,
      "institution": state.institution || null,
      "address": state.address || null,
      "country": state.country || null,
      "comment": state.comment || null,
      "userType": userType,
      "userID": userID,
      "timeStamp": state.timeStamp,
      "lastUpdated": timeStamp,
      "eventID": eventID,
      "activityGroupID": activityGroupID,
      "activityID": activityID,
      "role": "user",
      "selfReg": null
    })
    if (reRegisterBool && userType === "confirmed" && activityItem.ticketsLeft > 0) {
      updateDoc(activityRef,{
        "ticketsLeft": increment(-1)
      })
    }
    if (userType === "cancelled") {
      // Move waiting list person
      const waitingUser = Object.values(state.users).filter((item) => item.activityID === activityID && item.userType === "waiting").sort(CompareTimeStamp)[0];
      if (userType === "confirmed" && activityItem.open && waitingUser) {
        // Waiting user references
        const waitingUserIDRef = doc(db, "users", waitingUser.userID);
        // Move waiting user to registered
        updateDoc(waitingUserIDRef,{
          "userType": "confirmed",
          "lastUpdated": timeStamp
        })
      }
      else if (userType === "confirmed") {
        updateDoc(activityRef,{
          "ticketsLeft": increment(+1)
        })
      }
    }
    navigate("/activity-overview/" + eventID + "/" + activityGroupID + "/" + activityID);
    ResetFields();
  }

  if (user) return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <h1>Edit participant</h1>
        <p>Activity: {activityItem.activityName}</p>
        <form onSubmit={(e) => {e.preventDefault(); emailCheck()}}>
          {eventItem.titleField && <FormInputGroup translation={translation} type="title" required={eventItem.titleRequired} title="Title" className={eventItem.titleRequired && !state.title ? "required" : null} value={state.title} handleChange={handleChange} name="title" />}
          <FormInputGroup translation={translation} title="First name" type="text" required={true} className={state.firstName ? null : "required"} value={state.firstName} handleChange={handleChange} name="firstName" />
          <FormInputGroup translation={translation} title="Last name"  type="text" required={true} className={state.lastName ? null : "required"} value={state.lastName} handleChange={handleChange} name="lastName" />
          <FormInputGroup translation={translation} title="Email address" type="email" required={true} className={state.email ? null : "required"} value={state.email} handleChange={handleChange} name="email" />
          {eventItem.phoneField && <FormInputGroup translation={translation} title="Phone" required={eventItem.phoneRequired} type="text" className={eventItem.phoneRequired && !state.phone ? "required" : null} value={state.phone} handleChange={handleChange} name="phone" />}
          {eventItem.institutionField && <FormInputGroup translation={translation} required={eventItem.institutionRequired} title="Institution"type="text" className={eventItem.institutionRequired && !state.institution ? "required" : null} value={state.institution} handleChange={handleChange} name="institution" />}
          {eventItem.addressField && <FormInputGroup translation={translation} required={eventItem.addressRequired} title="Address" type="textarea" className={eventItem.addressRequired && !state.address ? "required" : null} value={state.address} handleChange={handleChange} name="address" />}
          {eventItem.countryField && <FormInputGroup translation={translation} required={eventItem.countryRequired} title="Country" type="country" className={eventItem.countryRequired && !state.country ? "required" : null} value={state.country} handleChange={handleChange} name="country" />}
          {eventItem.commentField && <FormInputGroup translation={translation} required={eventItem.commentRequired} title="Comment" type="textarea" className={eventItem.commentRequired && !state.comment ? "required" : null} value={state.comment} handleChange={handleChange} name="comment" />}
          <div className="card">
            <p><strong>Email history:</strong></p>
            {user.emailconfirmed && <p>Confirmation email: {user.emailconfirmed}</p>}
            {user.emailwaiting && <p>Waiting email: {user.emailwaiting}</p>}
            {user.emailcancelled && <p>Waiting email: {user.emailcancelled}</p>}
            {user.emailmovedToConfirmed && <p>Waiting moved to confirmed email: {user.emailmovedToConfirmed}</p>}
            {user.emailReminder && <p>Reminder email: {user.emailReminder}</p>}
            {user.emailAdhoc && <p>Adhoc email: {user.emailAdhoc}</p>}
            <button onClick={(e) => {e.preventDefault(); setExtraEmailCheck(true)}}>Send confirmation email</button>
          </div>
          <span className="gdpr">{translation[eventItem.language].gdprText}</span>
          <FormInputGroup translation={translation} title="I accept the privacy policy" type="checkbox" className={state.gdpr ? "checkboxGroup" : "checkboxGroup required"} checked={state.gdpr} handleChange={handleChange} name="gdpr" />
          <FormInputGroup translation={translation} title="I agree to be contacted by Hologic with information about products and services" className="checkboxGroup" type="checkbox" checked={state.optIn} handleChange={handleChange} name="optIn" />
          <FormInputGroup translation={translation} title="I want confirmation by email" type="checkbox" className="checkboxGroup" checked={state.emailConfirmation} handleChange={handleChange} name="emailConfirmation" />
          {state.userType !== "cancelled" && <button type="submit">Save</button>}
          {state.userType !== "cancelled" && <button className="edit" onClick={(e) => {e.preventDefault(); setDeleteCheck(true)}}>End participation</button>}
          {state.userType === "cancelled" && <button className="edit" onClick={(e) => {e.preventDefault(); setReRegisterCheck(true)}}>Renew participation</button>}
          <button className="edit" onClick={(e) => {e.preventDefault(); Back()}}>Cancel</button>
        </form>
      </section>
      {extraEmailCheck && <div className="modal">
        <div>
          <p>This will send a new email to the user</p>
          <button onClick={() => SendExtraEmailInvite()}>OK</button>
          <button onClick={() => setExtraEmailCheck(false)}>Cancel</button>
        </div>
      </div>}
      {reRegisterCheck && <div className="modal">
        <div>
          <p>Are you sure?</p>
          <button onClick={() => emailCheck(true)}>OK</button>
          <button onClick={() => setReRegisterCheck(false)}>Cancel</button>
        </div>
      </div>}
      {deleteCheck && <div className="modal">
        <div>
          <p>Are you sure?</p>
          <button onClick={() => { DeleteUser(eventID,activityGroupID,activityID,userType,userID); setDeleteCheck(false)}}>OK</button>
          <button onClick={() => setDeleteCheck(false)}>Cancel</button>
        </div>
      </div>}
    </div>
  )
  else return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <div>
        <h1>Page not found</h1>
        <p>Participant does not exist</p>
        </div>
      </section>
    </div>
  );
}
