import "./../App.css";
import {useEffect,useState} from "react";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import { useParams, useNavigate } from "react-router-dom";
import {db} from './../firebase';
import { doc, updateDoc, deleteDoc } from "firebase/firestore";

export const EditAdminUserPage = ({state,ShowModal,setState,ResetFields,Back,handleChange}) => {

  useEffect(() => {
    ViewEditAdminUserPage(userID);
  },[]);

  const { userID } = useParams();
  const [deleteCheck, setDeleteCheck] = useState(false);
  let emailCheckBool = false;
  const navigate = useNavigate();
  const user = state.users[userID];
  const userRef = doc(db, 'users', userID);

  const emailCheck = () => {
    if (state.email === state.oldEmail && state.password === state.oldPassword && state.admin === state.oldAdmin) {
      navigate("/admin");
      ResetFields();
    }
    // If fields empty return
    else if (!state.email || !state.password || !state.admin) {
      ShowModal("alert", "Please complete required fields");
      setState((prevState) => ({
        ...prevState,
        isLoading: false
      }));
      return;
    }
    // If email already exists
    else if (emailCheckBool) {
      ShowModal("alert", "Email address already exists");
      setState((prevState) => ({
        ...prevState,
        isLoading: false
      }));
      return;
    }
    // If password less too small
    else if (state.password.length < 6) {
      ShowModal("alert", "Password needs to be a minimum of 6 characters");
      setState((prevState) => ({
        ...prevState,
        isLoading: false
      }));
      return;
    }
    else EditAdminUser(userID);
  }

  const DeleteAdminUser = async (userID) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true
    }));
    deleteDoc(userRef);
    setState((prevState) => ({
      ...prevState,
      isLoading: false
    }));
    navigate("/admin");
    ResetFields();
  }

  const ViewEditAdminUserPage = (userID) => {
    if (!user) return;
    setState((prevState) => ({
      ...prevState,
      email: user.email,
      password: user.password,
      admin: user.role,
      oldEmail: user.email,
      oldPassword: user.password,
      oldAdmin: user.role,
      eventID: user.eventID
    }));
  }

  const EditAdminUser = async (userID) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true
    }));
    const timeStamp = Date.now();
    updateDoc(userRef, {
      "email": state.email,
      "password": state.password,
      "lastUpdated": timeStamp,
      "role": state.admin,
      "eventID": state.eventID
    });
    setState((prevState) => ({
      ...prevState,
      isLoading: false
    }));
    navigate("/admin");
    ResetFields();
    ShowModal("alert", "Admin user edited");
  }

  if (state.role !=="super" && state.role !== "manager") return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>You cannot access this page</h1>
      </section>
    </div>
  )
  if (user) return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <h1>Edit receptionist</h1>
        <form onSubmit={(e) => {e.preventDefault(); emailCheck()}}>
          {(state.role === "super" || (state.role === "manager" && state.admin === "reception")) && <FormInputGroup type="admin" required={true} className={!state.admin ? "required" : null} handleChange={handleChange} name="admin" value={state.admin} title="User type" /> }
          {/* <FormInputGroup events={state.events} eventID={state.eventID} type="eventID" required={true} className={!state.eventID ? "required" : null} handleChange={handleChange} name="eventID" value={state.eventID} title="Event" />  */}
          <FormInputGroup title="Email address" type="email" className={state.email ? null : "required"} required={true} value={state.email} handleChange={handleChange} name="email" />
          <FormInputGroup title="Password (minimum of 6 characters)" type="text" className={state.password.length < 6 ? "required" : null} required={true} value={state.password} handleChange={handleChange} name="password" />
          <button type="submit">Save</button>
          {(state.role === "super" || (state.role === "manager" && state.admin === "reception")) && <button onClick={(e) => {e.preventDefault(); setDeleteCheck(true)}}>Delete</button>}
        </form>
      </section>
      {deleteCheck && <div className="modal">
        <div>
          <p>Are you sure?</p>
          <button onClick={() => { DeleteAdminUser(userID); setDeleteCheck(false)}}>OK</button>
          <button onClick={() => setDeleteCheck(false)}>Cancel</button>
        </div>
      </div>}
    </div>
  )
  else return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <div>
        <h1>Page not found</h1>
        <p>User does not exist</p>
        </div>
      </section>
    </div>
  );
}
